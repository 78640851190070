import React, { useState, useEffect, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { AutoComplete } from "primereact/autocomplete";
import { Calendar } from "primereact/calendar";
import { addLocale } from "primereact/api";
import Services from "../../service/Services";
import "../../assets/css/Forms.css";
import "../../assets/css/DialogDemo.css";
/* import { Link} from 'react-router-dom' */
import Cookies from "universal-cookie";
import { Toast } from "primereact/toast";
import axios from "axios";
import '../../assets/css/CalendarSize.css'; 

import {
  SumarDias,
  ValidarMesCerrado,
  CambiarFormatoFecha,
  GetDates,
  obtenerHoraMaximaPorFecha
} from "../Funciones";

const cookies = new Cookies();

const BtnCrearActividadAdicional = (newDateArray) => {
  var idProveedor = cookies.get("idProveedor");
  const [servicioTipoActividad, setServicioTipoActividad] = useState([]);
  const [servicioAplicacion, setServicioAplicacion] = useState([]);
  const [servicioAreaNegocio, setServicioAreaNegocio] = useState([]);
  const [projectsSession, setProjectsSession] = useState([]);
  const [servicesUsers, setServicesUsers] = useState([]);
  const [cierres, setCierres] = useState([]);
  const [tipoNovedades, setTipoNovedades] = useState([]);
  const [servicesCategory, setServicesCategory] = useState([]);

  const getServices = new Services();

  const toast = useRef(null);

  const [displayBasic, setDisplayBasic] = useState(false);

  useEffect(() => {
    const source = axios.CancelToken.source();
   
    if (displayBasic === true) {
      getServices.getAreasNegocio().then((data) => {
        if (data !== undefined) {
          setServicioAreaNegocio(data);
        }
      });
      getServices.getCategory().then((data) => {
        if (data !== undefined) {
          setServicesCategory(data);
        }
      });
      getServices.getAplicaciones().then((data) => {
        if (data !== undefined) {
          setServicioAplicacion(data);
        }
      });
      getServices.getTipoActividades().then((data) => {
        if (data !== undefined) {
          setServicioTipoActividad(data);
        }
      });
      getServices.getAsociacionProyectoUsuarioVigente().then((data) => {
        if (data !== undefined) {
          setProjectsSession(data);
        }
      });
      getServices.getCierres().then((data) => {
        if (data !== undefined) {
          setCierres(data);
        }
      });
      getServices.getTipoNovedad().then((data) => {
        if (data !== undefined) {
          setTipoNovedades(data);
        }
      });
      getServices.getUsuariosWithIdNameAddActivity().then((data) => {
        if (data !== undefined) {
          setServicesUsers(data);
        }
      });
    }
    return () => {
      if (source) {
        getServices.cancelRequest();
      }
    };
  }, [displayBasic]); // eslint-disable-line react-hooks/exhaustive-deps

  const [setPosition] = useState("center");

  const dialogFuncMap = {
    displayBasic: setDisplayBasic,
  };

  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);

    if (position) {
      setPosition(position);
    }
  };

  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
  };

  /// Proceso para llenar los clientes y los proyectos asociados al usuario en sesión

  var clientsUserSession = [];

  //// llenar clientes asociados al usuario en sesión
  projectsSession.forEach((element) => {
    clientsUserSession.push({
      id: element.cliente.id,
      razonsocial: element.cliente.nombre,
    });
  });
  const tableClients = {};
  const uniqueClients = clientsUserSession.filter(function (object) {
    return tableClients.hasOwnProperty(object.id)
      ? false
      : (tableClients[object.id] = true);
  });

  //Obtener datos del select tipo de clientes

  const [selectedCliente, setSelectedCliente] = useState({
    cliente: "",
    id: "",
  });

  const clientes = uniqueClients.map((item) => {
    const av = { cliente: item.razonsocial, id: item.id };

    return av;
  });

  const [validarCambio, setValidarCambio] = useState(false);

  const onClienteChange = (e) => {
    setSelectedCliente(e.value);
    setValidarCambio(true);
  };

  //// llenar proyectos asociados sugún el cliente seleccionado

  var proyectsUserSession = [];
  var proyectoFechaVigente = [];
  var d = new Date();
  var dateV = CambiarFormatoFecha(d);

  const result = projectsSession.filter(function (object) {
    var idClient = object.cliente.id.toString();
    return idClient.includes(selectedCliente.id);
  });

  result.forEach((element) => {
    proyectsUserSession.push({
      id: element.id,
      nombreproyecto: element.nombre,
      fechafinvigencia: element.fechafinvigencia,
    });
  });

  const filterDate = proyectsUserSession.filter((element) => {
    return element.fechafinvigencia >= dateV;
  });
  filterDate?.forEach((element) => {
    proyectoFechaVigente.push(element);
  });

  var dateValidError = false;
  var messageDateValidError = "";

  if (proyectoFechaVigente.length === 0 && selectedCliente.cliente !== "") {
    dateValidError = true;
    messageDateValidError =
      "No existe un proyecto con fecha fin de vigencia mayor a la actual";
  } else {
    dateValidError = false;
    messageDateValidError = "";
  }

  //Obtener datos del select tipo de proyecto

  const [selectedProyecto, setSelectedProyecto] = useState({
    proyecto: "",
    id: "",
  });
  const proyectos = proyectoFechaVigente.map((item) => {
    const av = { proyecto: item.nombreproyecto, id: item.id };
    return av;
  });

  const onProyectoChange = (e) => {
    setSelectedProyecto(e.value);
    setValidarCambio(true);
  };
  //Obtener datos de categoria
  const [selectedCategoryComplete, setSelectedCategoryComplete] =
    useState(null);
  const [filteredCategory, setFilteredCategory] = useState(null);

  const searchCategory = (event) => {
    setTimeout(() => {
      let _filteredCategory;
      if (!event.query.trim().length) {
        _filteredCategory = [...servicesCategory];
      } else {
        _filteredCategory = servicesCategory.filter((element) => {
          return element.descripcion
            .toLowerCase()
            .startsWith(event.query.toLowerCase());
        });
      }

      setFilteredCategory(_filteredCategory);
    }, 250);
  };

  const onCategoryCompleteChange = (e) => {
    setSelectedCategoryComplete(e.value);
    setValidarCambio(true);
    setSelectedTipoActividadComplete(null);
  };

  //Obtener datos del select tipo de actividades
  const [servicesTypeActivityForCategory, setServicesTypeActivityForCategory] =
    useState([]);
  useEffect(() => {
    //Obtener los tipos de actividad asociado a cada categoria
    if (selectedCategoryComplete !== null) {
      const filter = servicioTipoActividad.filter((obj) => {
        return obj.categoria.id === selectedCategoryComplete.id;
      });
      setServicesTypeActivityForCategory(filter);
    }
  }, [selectedCategoryComplete]); // eslint-disable-line react-hooks/exhaustive-deps

  //Obtener datos del select tipo de actividades

  const [selectedTipoActividadComplete, setSelectedTipoActividadComplete] =
    useState(null);
  const [filteredTipoActividad, setFilteredTipoActividad] = useState(null);

  const searchTipoActividad = (event) => {
    setTimeout(() => {
      let _filteredTipoActividad;
      if (!event.query.trim().length) {
        _filteredTipoActividad = [...servicesTypeActivityForCategory];
      } else {
        _filteredTipoActividad = servicesTypeActivityForCategory.filter(
          (element) => {
            return element.nombretipoactividad
              .toLowerCase()
              .startsWith(event.query.toLowerCase());
          }
        );
      }

      setFilteredTipoActividad(_filteredTipoActividad);
    }, 250);
  };

  const onTipoActividadCompleteChange = (e) => {
    setSelectedTipoActividadComplete(e.value);
    setValidarCambio(true);
    validarTipoActividad();
  };
  //Obtener datos del select área de negocio

  const [selectedAreaComplete, setSelectedAreaComplete] = useState(null);
  const [filteredArea, setFilteredArea] = useState(null);

  const searchArea = (event) => {
    setTimeout(() => {
      let _filteredArea;
      if (!event.query.trim().length) {
        _filteredArea = [...servicioAreaNegocio];
      } else {
        _filteredArea = servicioAreaNegocio.filter((element) => {
          return element.nombreareanegocio
            .toLowerCase()
            .startsWith(event.query.toLowerCase());
        });
      }

      setFilteredArea(_filteredArea);
    }, 250);
  };

  const onAreaCompleteChange = (e) => {
    setSelectedAreaComplete(e.value);
    setValidarCambio(true);
  };

  //Obtener datos del select Sistema

  const [selectedSistemaComplete, setSelectedSistemaComplete] = useState(null);
  const [filteredSistema, setFilteredSistema] = useState(null);

  //// llenar aplicaciones asociados sugún el cliente seleccionado

  var AplicationsClients = [];

  const resultAplication = servicioAplicacion.filter(function (object) {
    var idClient = object.idcliente.id.toString();
    return idClient === selectedCliente.id;
  });

  resultAplication.forEach((element) => {
    AplicationsClients.push(element);
  });

  const searchSistema = (event) => {
    setTimeout(() => {
      let _filteredSistema;
      if (!event.query.trim().length) {
        _filteredSistema = [...AplicationsClients];
      } else {
        _filteredSistema = AplicationsClients.filter((element) => {
          return element.nombreaplicacion
            .toLowerCase()
            .startsWith(event.query.toLowerCase());
        });
      }

      setFilteredSistema(_filteredSistema);
    }, 250);
  };

  const onSistemaCompleteChange = (e) => {
    setSelectedSistemaComplete(e.value);
    setValidarCambio(true);
  };

  //Obtener datos del input ticket
  const [inputTicket, setInputTicket] = useState({
    ticket: "",
  });

  const onTicketChange = (event) => {
    setInputTicket({
      ...inputTicket,
      [event.target.name]: event.target.value,
    });
    setValidarCambio(true);
  };
  //Obtener datos del input Coordinado con

  const [inputCoordinadoCon, setInputCoordinadoCon] = useState({
    coordinadoCon: "",
  });

  const onCoordinadoConChange = (event) => {
    setInputCoordinadoCon({
      ...inputCoordinadoCon,
      [event.target.name]: event.target.value,
    });
    setValidarCambio(true);
  };
  //Obtener datos del input actividad

  const [inputActividad, setInputActividad] = useState({
    actividad: "",
  });

  const onActividadChange = (event) => {
    setInputActividad({
      ...inputActividad,
      [event.target.name]: event.target.value,
    });
    setValidarCambio(true);
  };
  //Obtener datos del input número de horas

  const [inputHoras, setInputHoras] = useState({
    horas: "",
  });

  const onHorasChange = (event) => {  
    
    const newValue = event.target.value;
        if ((newValue.match(/\./g) || []).length > 1 || newValue.startsWith('.')) {
            return;
        }

    setInputHoras({
      ...inputHoras,
      [event.target.name]: event.target.value,
    });
    setValidarCambio(true);
  };

  /// calendar de fecha

  const [date, setDate] = useState(null);

  const onConvertirFechaChange = (e) => {
    let fecha = e.value;
    setDate(fecha);
    consultActivities(e.value, usuario);
    setValidarCambio(true);
    if (fecha !== null) {
      var validacion = ValidarMesCerrado(fecha, minFechaCierre, disabledDates);
      setFechaError(validacion.setFechaError);
      setMensajeFecha(validacion.setMensajeFecha);
      setValidarTipoActividad(validacion.setMostrarAdvertencia);
      setValidarTipoActividadMensaje(validacion.setMostrarAdvertenciaMensaje);
      setValidarMes(validacion.setValidarMes);
    }
    setSelectedDate(e.value);
  };

  const [errorHorasADedicar, setErrorHorasADedicar] = useState(false);
  const [mensajeErrorHorasADedicar, setMensajeErrorHorasADedicar] = useState("");
  const historicoHorasJSON = sessionStorage.getItem("historicoHoras");
  const horasADedicar = JSON.parse(historicoHorasJSON)
  // eslint-disable-next-line no-unused-vars
  const [sumatoriaHoras, setSumatoriaHoras] = useState(0);
  const consultActivities = async (date, usuarioSesion) => {

   if (date !== null) {
      const url = process.env.REACT_APP_BASE_URL + "/v1/api/activity/consultListActivity";
      const newDate = CambiarFormatoFecha(date);

      const json = JSON.stringify({
        esadicional: "0",
        fecFinal: newDate,
        fecInicial: newDate,
        usuario: usuarioSesion,
      });
      await axios
        .post(url, json, {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
          /* cancelToken: source?.token */
        })
        .then((response) => {

          let sumaHoras = 0;
          response.data.forEach((data) => {
            const calculoHoras = data.numerohoras;
            sumaHoras += calculoHoras;
          });

          setSumatoriaHoras(sumaHoras);

          const horasMaximaPorFecha = obtenerHoraMaximaPorFecha(horasADedicar, [newDate]);
          const horasMaximas = parseFloat(horasMaximaPorFecha[newDate]);

          if (parseFloat(horasMaximaPorFecha[CambiarFormatoFecha(date)]) === 0) {
            setErrorHorasADedicar(true)
            setMensajeErrorHorasADedicar("Usuario No controla horas, por ende no está permitido registrar actividades adicionales")
          } else if (parseFloat(horasMaximas) > sumaHoras) {
            setErrorHorasADedicar(true);
            setMensajeErrorHorasADedicar("Para la fecha seleccionada no se pueden registrar actividades adicionales, hasta que cumpla con el máximo de horas configuradas para el usuario");
          } else {
            setErrorHorasADedicar(false);
            setMensajeErrorHorasADedicar("");
          }

          /* console.log(response); */
          response.data.forEach((data) => {

            /*if (horasMaximas === null) {
                  setErrorHorasADedicar(true)
                  setMensajeErrorHorasADedicar("")*/
                  
          });
        })
        .catch((err) => 
          //console.log
        (err));
    }
  };

  const [dateCorreo, setDateCorreo] = useState(null);

  const onConvertirFechaCorreoChange = (e) => {
    let fecha = e.value;
    setDateCorreo(fecha);
    setValidarCambio(true);
    setSelectedDate(e.value);
  };

  addLocale("es", {
    firstDayOfWeek: 1,
    dayNames: [
      "domingo",
      "lunes",
      "martes",
      "miércoles",
      "jueves",
      "viernes",
      "sábado",
    ],
    dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
    dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
    monthNames: [
      "enero",
      "febrero",
      "marzo",
      "abril",
      "mayo",
      "junio",
      "julio",
      "agosto",
      "septiembre",
      "octubre",
      "noviembre",
      "diciembre",
    ],
    monthNamesShort: [
      "ene",
      "feb",
      "mar",
      "abr",
      "may",
      "jun",
      "jul",
      "ago",
      "sep",
      "oct",
      "nov",
      "dic",
    ],
    today: "Hoy",
    clear: "Limpiar",
  });

  //Validar si hubo algun cambio

  var Cambios = false;
  if (validarCambio === true) {
    Cambios = true;
  }

  //Almacenamos los valores para enviarlos por props
  const fecha = date;
  const cliente = selectedCliente.cliente;
  const idCliente = selectedCliente.id;
  const proyecto = selectedProyecto.proyecto;
  const idProyecto = selectedProyecto.id;

  var tipoActividad;
  var idTipoActividad;
  if (selectedTipoActividadComplete !== null) {
    tipoActividad = selectedTipoActividadComplete.nombretipoactividad;
    idTipoActividad = selectedTipoActividadComplete.id;
  }

  const ticket = inputTicket.ticket;

  var area;
  var idArea;
  if (selectedAreaComplete !== null) {
    area = selectedAreaComplete.nombreareanegocio;
    idArea = selectedAreaComplete.id;
  }

  var sistema;
  var idSistema;
  if (selectedSistemaComplete !== null) {
    sistema = selectedSistemaComplete.nombreaplicacion;
    idSistema = selectedSistemaComplete.id;
  }

  const fechaCorreo = dateCorreo;
  const coordinadoCon = inputCoordinadoCon.coordinadoCon;
  const actividad = inputActividad.actividad;
  const horas = inputHoras.horas;
  const idColaborador = cookies.get("idColaborador");
  const usuario = cookies.get("usuario");

  // Modal para el botón regresar

  const [actividadRegistrada, setActividadRegistrada] = useState(false);

  const ModalRegresar = () => {
    const [displayConfirmation, setDisplayConfirmation] = useState(false);
    const [setPosition] = useState("center");

    const dialogFuncMap = {
      displayConfirmation: setDisplayConfirmation,
    };

    const onClick = (name, position) => {
      dialogFuncMap[`${name}`](true);

      if (position) {
        setPosition(position);
      }
    };

    const onHide = (name) => {
      dialogFuncMap[`${name}`](false);
    };

    const Regresar = (name) => {
      setErrorDateStartUserSession(false);
      setErrorMessageDateStartUserSession("");
      if (actividadRegistrada === true) {
        window.location.href = "/registroActividades/consultarActividades/1";
      } else {
        setSelectedCliente({
          cliente: "",
          id: "",
        });
        setSelectedProyecto({
          proyecto: "",
          id: "",
        });

        setSelectedTipoActividadComplete(null);
        setSelectedCategoryComplete(null);
        setSelectedAreaComplete(null);
        setErrorHorasADedicar(false);
        setMensajeErrorHorasADedicar("");
        setSelectedSistemaComplete(null);

        setInputActividad({
          actividad: "",
        });
        setInputHoras({
          horas: "",
        });
        setInputTicket({
          ticket: "",
        });
        setInputCoordinadoCon({
          coordinadoCon: "",
        });
        setDate(null);
        setDateCorreo(null);

        setFechaError(false);
        setMensajeFecha("");

        setValidarCambio(false);

        setDisplayBasic(false);
        dialogFuncMap[`${name}`](false);
      }
    };
    const renderFooter = (name) => {
      return (
        <div>
          <Button
            label="Cancelar"
            icon="pi pi-times"
            onClick={() => onHide(name)}
            className="p-button-text"
          />
          <Button
            label="Aceptar"
            icon="pi pi-check"
            onClick={() => Regresar(name)}
            autoFocus
          />
        </div>
      );
    };

    return (
      <>
        <Button
          label="Regresar"
          icon="pi pi-times"
          onClick={() => onClick("displayConfirmation")}
          className="p-button-text"
        />

        <Dialog
          className="dialog-demo"
          header="Advertencia"
          visible={displayConfirmation}
          modal
          style={{}}
          footer={renderFooter("displayConfirmation")}
          onHide={() => onHide("displayConfirmation")}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle p-mr-3"
              style={{ fontSize: "2rem" }}
            />
            <span style={{ textAlign: "center" }}>
              No se ha guardado la información registrada, ¿desea continuar?
            </span>
          </div>
        </Dialog>
      </>
    );
  };

  const RegistrarActividad = () => {
    const [disabled, setDisabled] = useState(false);
    const [displayConfirmation, setDisplayConfirmation] = useState(false);
    const [setPosition] = useState("center");

    const dialogFuncMap = {
      displayConfirmation: setDisplayConfirmation,
    };

    const onClick = (name, position) => {
      dialogFuncMap[`${name}`](true);

      if (position) {
        setPosition(position);
      }
    };

    const onHide = (name) => {
      dialogFuncMap[`${name}`](false);
    };
    const indicador = 1;

    const Registrar = async (agregarMas) => {
      setDisabled(true);
      const urlRegistrar =
        process.env.REACT_APP_BASE_URL + "/v1/api/activity/registerActivity";

      let month = date.getMonth() + 1;
      let day = date.getDate();

      if (month < 10) {
        month = "0" + month;
      }

      if (day < 10) {
        day = "0" + day;
      }
      const newDate = date.getFullYear() + "-" + month + "-" + day;

      const json = JSON.stringify({
        actualizadopor: "",
        aplicacion: {
          id: idSistema,
        },
        areanegocio: {
          id: idArea,
        },
        cliente: {
          id: idCliente,
        },
        colaborador: {
          id: idColaborador,
        },
        coordinadocon: coordinadoCon,
        creadopor: usuario,
        descripcionactividad: actividad,
        esadicional: 1,
        estado: 1,
        fecha: newDate,
        fechaactualizado: "",
        fechacorreoelec: fechaCorreo,
        fechacreacion: "",
        idempresa: {
          id: 1,
        },
        numerohoras: horas,
        proyecto: {
          id: idProyecto,
        },
        tipoActividad: {
          id: idTipoActividad,
        },
        ticket: ticket,
        idproveedor: parseInt(idProveedor),
      });
      /* console.log(json) */
      await axios
        .post(urlRegistrar, json, {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          toast.current.show({
            severity: "success",
            summary: "Se ha registrado la actividad",
            detail: response.data.message,
            life: 3000,
          });
          //console.log(response.data)
          if (agregarMas === false) {
            window.location.href =
              "/registroActividades/consultarActividades/" + indicador;
          }
          setDisplayConfirmation(false);
        })
        .catch(function (error) {
          //console.log(error.response)
        });
    };
    const Regresar = (name, agregarMas) => {
      Registrar(agregarMas);
      if (agregarMas === true) {
        setInputActividad({
          actividad: "",
        });
        setInputHoras({
          horas: "",
        });
        setActividadRegistrada(true);

        setDisplayBasic(true);

        dialogFuncMap[`${name}`](false);
      }
    };
    const renderFooter = (name) => {
      return (
        <div>
          <Button
            label="No"
            icon="pi pi-times"
            onClick={() => Regresar(name, false)}
            className="p-button-text"
            disabled={disabled}
          />
          <Button
            label="Si"
            icon="pi pi-check"
            onClick={() => Regresar(name, true)}
            autoFocus
            disabled={disabled}
          />
        </div>
      );
    };

    return (
      <>
        <Button
          label="Guardar"
          icon="pi pi-plus-circle"
          onClick={() => onClick("displayConfirmation")}
          className=""
        />

        <Dialog
          className="dialog-demo"
          header="Advertencia"
          visible={displayConfirmation}
          modal
          breakpoints={{ "960px": "75vw" }}
          style={{ width: "50vw" }}
          footer={renderFooter("displayConfirmation")}
          onHide={() => onHide("displayConfirmation")}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle p-mr-3"
              style={{ fontSize: "2rem" }}
            />
            <span style={{ textAlign: "center" }}>
              La información ingresada se guardará en su registro de
              Actividades, ¿Desea agregar otra actividad?
            </span>
          </div>
        </Dialog>
      </>
    );
  };

  const renderFooter = (name) => {
    return (
      <div>
        {Cambios ? (
          <ModalRegresar />
        ) : (
          <Button
            label="Regresar"
            icon="pi pi-times"
            onClick={() => onHide(name)}
            className="p-button-text"
          />
        )}
        {permisoRegistrar ? (
          <>
            <RegistrarActividad />
          </>
        ) : (
          ""
        )}
      </div>
    );
  };
  //validaciones

  const [fechaError, setFechaError] = useState(false);
  const [mensajeFecha, setMensajeFecha] = useState("");

  var clienteError = false;
  var mensajeCliente = "";

  var proyectoError = false;
  var mensajeProyecto = "";

  var disabledProyecto;

  if (cliente === "") {
    disabledProyecto = true;
  } else {
    disabledProyecto = false;
  }

  var categoriaError = false;
  var mensajeCategoria = "";

  if (typeof selectedCategoryComplete === "string") {
    categoriaError = true;
    mensajeCategoria = "Resultado no encontrado";
  }

  var tipoActividadError = false;
  var mensajeTipoActividad = "";

  if (typeof selectedTipoActividadComplete === "string") {
    tipoActividadError = true;
    mensajeTipoActividad = "Resultado no encontrado";
  }

  var areaError = false;
  var mensajeArea = "";

  if (typeof selectedAreaComplete === "string") {
    areaError = true;
    mensajeArea = "Resultado no encontrado";
  }

  var sistemaError = false;
  var mensajeSistema = "";

  if (typeof selectedSistemaComplete === "string") {
    sistemaError = true;
    mensajeSistema = "Resultado no encontrado";
  }

  if (selectedCliente.id !== "") {
    if (AplicationsClients.length === 0) {
      sistemaError = true;
      mensajeSistema =
        "El cliente seleccionado no tiene aplicaciones asociadas";
    }
  }

  var ticketError = false;
  var mensajeTicket = "";
  if (
    tipoActividad === "Soporte-Requerimientos" ||
    tipoActividad === "Soporte-Incidencias"
  ) {
    ticketError = true;
    mensajeTicket = "Debes ingresar un ticket";
    if (ticket !== "") {
      if (ticket.length < 2) {
        ticketError = true;
        mensajeTicket = "El ticket debe tener más de 2 dígitos";
      } else if (ticket.length > 50) {
        ticketError = true;
        mensajeTicket = "El ticket debe tener menos de 50 dígitos";
      } else {
        ticketError = false;
        mensajeTicket = "";
      }
    }
  } else {
    ticketError = false;
    mensajeTicket = "";
  }

  var actividadError = false;
  var mensajeActividad = "";
  if (actividad.length < 10 && actividad !== "") {
    actividadError = true;
    mensajeActividad = "La descripción es muy corta";
  } else if (actividad.length > 300) {
    actividadError = true;
    mensajeActividad = "La descripción es demasiado larga ";
  }
  var horasError = false;
  var mensajeHoras = "";
  if (horas <= 0 && horas !== "") {
    horasError = true;
    mensajeHoras = "El número debe ser mayor a 0 horas";
  } else if (horas > 24) {
    horasError = true;
    mensajeHoras = "El número debe ser menor a 24 horas";
  }

  var fechaCorreoError = false;
  var mensajeFechaCorreo = "";

  var coordinadoConError = false;
  var mensajeCoordinadoCon = "";
  if (coordinadoCon.length < 3 && coordinadoCon !== "") {
    coordinadoConError = true;
    mensajeCoordinadoCon = "Debe ingresar más de 3 caracteres";
  }

  // Mes cerrado definitivamente
  var minFechaCierre;
  var disabledDates;

  var idUsuario = cookies.get("idUsuario");
  const resultUser = servicesUsers.filter((item) => {
    return parseInt(item.idUsuario) === parseInt(idUsuario);
  });
  var mesAbierto = [];
  if (resultUser.length !== 0) {
    resultUser.forEach((element) => {
      mesAbierto = cierres.filter(function (objeto) {
        var id = element.blnAddActivitivy;
        return objeto.id === id;
      });
    });
  }
  const mesesCerrados = cierres.filter(function (objeto) {
    return objeto.estado === "C";
  });
  var fechaCierre = [];
  if (mesAbierto.length === 0) {
    cierres.forEach((element) => {
      fechaCierre.push(element.fechaFinalCierre);
    });
  } else {
    mesesCerrados.forEach((element) => {
      fechaCierre.push(element.fechaFinalCierre);
    });
  }

  let arrayFechasCierres = fechaCierre.map((item) => new Date(item));
  var fechaUltimoCierre = new Date(Math.max.apply(null, arrayFechasCierres));
  var getMesAbierto = {};

  mesAbierto.forEach((element) => {
    getMesAbierto = {
      estado: element.estado,
      fechaFinalCierre: element.fechaFinalCierre,
      fechaInicialCierre: element.fechaInicialCierre,
    };
  });

  var convertirFechaFinal = new Date(getMesAbierto.fechaFinalCierre);
  var newFechaFinalCierre = SumarDias(convertirFechaFinal, +2);

  var convertirFechaInicial = new Date(getMesAbierto.fechaInicialCierre);
  var newFechaInicialCierre = SumarDias(convertirFechaInicial, +1);

  /* console.log(getMesAbierto.fechaInicialCierre)
    console.log(getMesAbierto.fechaFinalCierre) */

  var dateArray = GetDates(newFechaFinalCierre, fechaUltimoCierre.addDays(2));

  cierres.sort(function (a, b) {
    var textA = a.id;
    var textB = b.id;
    return textA - textB;
  });

  if (dateArray.length !== 0) {
    minFechaCierre = newFechaInicialCierre;
    disabledDates = dateArray;
  } else {
    minFechaCierre = SumarDias(fechaUltimoCierre, +1);
    disabledDates = [];
  }

  // Modal para validar si es una actividad o una novedad

  const [ValidarTipoActividad, setValidarTipoActividad] = useState(false);
  const [ValidarTipoActividadMensaje, setValidarTipoActividadMensaje] =
    useState("");

  const [validarMes, setValidarMes] = useState(false);

  const validarTipoActividad = () => {
    tipoNovedades.forEach((element) => {
      if (tipoActividad === element.descripcion) {
        setValidarTipoActividad(true);
        setValidarTipoActividadMensaje(
          "Señor usuario la actividad seleccionada pertenece a una novedad, debe dirigirse a la pestaña novedades para registrarla"
        );
        setValidarMes(false);
      }
    });
  };

  const MensajeTipoActividad = ({ mostrarMensajeProp, mensajeProp }) => {
    const [displayBasic2, setDisplayBasic2] = useState(mostrarMensajeProp);

    const dialogFuncMap = {
      displayBasic: setDisplayBasic2,
    };

    const onHide = (name) => {
      setValidarTipoActividad(false);
      setValidarTipoActividadMensaje("");

      setValidarCambio(false);
      if (validarMes !== true) {
        setDisplayBasic(false);
        setSelectedCliente({
          cliente: "",
          id: "",
        });
        setSelectedProyecto({
          proyecto: "",
          id: "",
        });
        setErrorDateStartUserSession(false);
        setErrorMessageDateStartUserSession("");
        setSelectedTipoActividadComplete(null);

        setSelectedAreaComplete(null);

        setSelectedSistemaComplete(null);

        setInputActividad({
          actividad: "",
        });
        setInputHoras({
          horas: "",
        });
        setInputTicket({
          ticket: "",
        });
        setDate(null);
      }
      setDisplayBasic2(false);
      dialogFuncMap[`${name}`](false);
    };

    const renderFooter = (name) => {
      return (
        <div>
          {/* <Button label="No" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" /> */}
          <Button
            label="Aceptar"
            icon="pi pi-check"
            onClick={() => onHide(name)}
            autoFocus
          />
        </div>
      );
    };

    return (
      <div className="dialog-demo">
        <div className="card">
          <Dialog
            className="dialog-demo"
            header="Advertencia"
            visible={displayBasic2}
            modal
            footer={renderFooter("displayBasic")}
            onHide={() => onHide("displayBasic")}
            breakpoints={{ "960px": "75vw" }}
          >
            <div className="confirmation-content">
              <i
                className="pi pi-exclamation-triangle p-mr-3"
                style={{ fontSize: "2rem" }}
              />
              <span style={{ textAlign: "center" }}>{mensajeProp}</span>
            </div>
          </Dialog>
        </div>
      </div>
    );
  };

  // Sé valida que las fechas de registrar actividad no sean menores que la fecha de ingreso del usuario en sessión
  const [errorDateStartUserSession, setErrorDateStartUserSession] =
    useState(false);
  const [
    errorMessageDateStartUserSession,
    setErrorMessageDateStartUserSession,
  ] = useState("");
  useEffect(() => {
    const dateStartUserSession = cookies.get("fechaIngreso");
    let arrDate = [];
    if (date !== null) {
      if (CambiarFormatoFecha(date) < dateStartUserSession) {
        arrDate.push(date);
      }
      if (arrDate.length !== 0) {
        setErrorDateStartUserSession(true);
        setErrorMessageDateStartUserSession(
          `No puedes registrar actividades con fecha menor a su fecha de ingreso, la cuál es ${dateStartUserSession}`
        );
      } else {
        setErrorDateStartUserSession(false);
        setErrorMessageDateStartUserSession("");
      }
    }
  }, [date]); // eslint-disable-line react-hooks/exhaustive-deps

   // advertencia festivos.
   const [holidays, setHolidays] = useState([]);
   const [selectedDate, setSelectedDate] = useState(null);
   
   useEffect(() => {
    if (displayBasic === true) {
       getServices.getCalendarios()
           .then((data) => {
               if (!data || !Array.isArray(data)) {
                   return;
               }
   
               const colombianHolidays = data
                   .filter((holiday) => holiday.pais === "Colombia")
                   .flatMap((holiday) =>
                       holiday.fechas.split(",").map((date) =>
                           CambiarFormatoFecha(new Date(date))
                       )
                   );
   
               setHolidays(colombianHolidays);
           })
           .catch((error) => {
               //console.error("Error al obtener los calendarios:", error);
           });
      }
  },[displayBasic]); // eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
       if (holidays.length > 0 && newDateArray?.length > 0) {
           const formattedNewDates = newDateArray.map((date) => CambiarFormatoFecha(new Date(date)));
           formattedNewDates.filter((date) => !holidays.includes(date));
           
       }
   }, [holidays, newDateArray]); // eslint-disable-line react-hooks/exhaustive-deps

   // Función para verificar fechas festivas
   const verificarFechasFestivas = (fechas) => {
       let fechasFestivasSeleccionadas = [];
       const formattedDates = Array.isArray(fechas)
           ? fechas.map((date) => CambiarFormatoFecha(date))
           : [CambiarFormatoFecha(fechas)];

           fechasFestivasSeleccionadas = formattedDates.filter((date) => holidays.includes(date));

       if (fechasFestivasSeleccionadas.length === 1) {
           const fecha = fechasFestivasSeleccionadas[0];
           toast.current.show({
               severity: "warn",
               summary: "¡Advertencia Día festivo detectado!",
               detail: `El día seleccionado: ${fecha} es un festivo. Esto no afecta el registro. Puedes continuar sin problemas.`,
               life: 12000,
           });
       }else if (fechasFestivasSeleccionadas.length > 1) {
           const fechas = fechasFestivasSeleccionadas.join(", ");
           toast.current.show({
               severity: "warn",
               summary: "¡Advertencia Días festivos detectados!",
               detail: `Los días seleccionados: ${fechas} son festivos. Esto no afecta al registro. Puedes continuar sin problema.`,
               life: 12000,
           });
           
       }else {
           //console.log("No se encontraron fechas festivas seleccionadas.");
       }
   };

const onHolidayDate = () => {
  if (selectedDate) {
    verificarFechasFestivas(selectedDate); 
}
};
  //pintar los dias festivos con color para identificar 
  const festivoTemplate = (date) => {
    const dateObj = new Date(Date.UTC(date.year, date.month, date.day));

    const isHoliday = holidays.some((holiday) => {
        const holidayDate = new Date(holiday);
        return dateObj.getTime() === holidayDate.getTime();
        });

        const normalizedSelectedDate = Array.isArray(selectedDate)
        ? selectedDate
        : selectedDate
        ? [selectedDate]
        : [];

    const isSelected = normalizedSelectedDate.some((selected) => {
        const selectedDateObj = new Date(selected);
        return (
            dateObj.getUTCDate() === selectedDateObj.getUTCDate() &&
            dateObj.getUTCMonth() === selectedDateObj.getUTCMonth() &&
            dateObj.getUTCFullYear() === selectedDateObj.getUTCFullYear()
        );
    });

    const className = isHoliday
        ? isSelected
            ? "holiday-selected"
            : "holiday"
        : isSelected
        ? "p-highlight"
        : "";

    return (
        <div className={`p-datepicker-day ${className}`}>
            {date.day}
        </div>
    );
};

  var permisoRegistrar = false;
  if (
    fechaError === false &&
    fecha !== null &&
    clienteError === false &&
    cliente !== "" &&
    proyectoError === false &&
    proyecto !== "" &&
    categoriaError === false &&
    tipoActividadError === false &&
    tipoActividad !== "" &&
    selectedTipoActividadComplete !== null &&
    areaError === false &&
    selectedAreaComplete !== null &&
    area !== "" &&
    sistemaError === false &&
    selectedSistemaComplete !== null &&
    sistema !== "" &&
    ticketError === false &&
    actividadError === false &&
    actividad !== "" &&
    horasError === false &&
    horas !== "" &&
    fechaCorreoError === false &&
    fechaCorreo !== null &&
    coordinadoConError === false &&
    coordinadoCon !== "" &&
    errorDateStartUserSession === false &&
    errorHorasADedicar === false
  ) {
    permisoRegistrar = true;
  }
  return (
    <div className="">
      <Toast ref={toast} />
      <div>
        <button className="btnAdd" onClick={() => onClick("displayBasic")}>
          <span className="pi pi-plus Add"></span>Agregar Actividad Adicional{" "}
        </button>

        <MensajeTipoActividad
          mostrarMensajeProp={ValidarTipoActividad}
          mensajeProp={ValidarTipoActividadMensaje}
        />
        <Dialog
          className="modal"
          header="Registrar Actividad Adicional"
          visible={displayBasic}
          style={{}}
          footer={renderFooter("displayBasic")}
          onHide={() => onHide("displayBasic")}
        >
          <div className="p-fluid p-formgrid p-grid">
            <form style={{ display: "contents" }}>
              <div className="p-field p-col-12 p-md-6">
                <label className="labels">
                  Fecha<span className="require">*</span>
                </label>
                <Calendar
                  placeholder="Seleccione una fecha"
                  value={date}
                  onChange={onConvertirFechaChange}
                  onHide={onHolidayDate}
                  dateTemplate={festivoTemplate}
                  dateFormat="dd-mm-yy"
                  locale="es"
                  className={
                    fechaError ||
                    errorDateStartUserSession ||
                    errorHorasADedicar
                      ? "p-invalid"
                      : ""
                  }
                />
                <p className="mensajeError">
                  {errorDateStartUserSession
                    ? errorMessageDateStartUserSession
                    : ""}
                </p>
                <p className="mensajeError">{fechaError ? mensajeFecha : ""}</p>
                <p className="mensajeError">
                  {errorHorasADedicar ? mensajeErrorHorasADedicar : ""}
                </p>
              </div>
              <div className="p-field p-col-12 p-md-6">
                <label className="labels">
                  Fecha Correo<span className="require">*</span>
                </label>
                <Calendar
                  placeholder="Seleccione una fecha"
                  value={dateCorreo}
                  onChange={onConvertirFechaCorreoChange}
                  onHide={onHolidayDate}
                  dateTemplate={festivoTemplate}
                  dateFormat="dd-mm-yy"
                  locale="es"
                  className={fechaCorreoError ? "p-invalid" : ""}
                />
                <p className="mensajeError">
                  {fechaCorreoError ? mensajeFechaCorreo : ""}
                </p>
              </div>
              <div className="p-field p-col-12 p-md-6">
                <label className="labels">
                  Cliente<span className="require">*</span>
                </label>
                <Dropdown
                  value={selectedCliente}
                  options={clientes}
                  onChange={onClienteChange}
                  name="cliente"
                  optionLabel="cliente"
                  placeholder="Seleccione un cliente"
                  filter
                  className={clienteError ? "p-invalid" : ""}
                />
                <p className="mensajeError">
                  {clienteError ? mensajeCliente : ""}
                </p>
              </div>
              <div className="p-field p-col-12 p-md-6">
                <label className="labels">
                  Proyecto<span className="require">*</span>
                </label>
                <Dropdown
                  value={selectedProyecto}
                  options={proyectos}
                  onChange={onProyectoChange}
                  name="proyecto"
                  optionLabel="proyecto"
                  placeholder="Seleccione un proyecto"
                  disabled={disabledProyecto}
                  filter
                  className={proyectoError || dateValidError ? "p-invalid" : ""}
                />
                <p className="mensajeError">
                  {proyectoError ? mensajeProyecto : ""}
                </p>
                <p className="mensajeError">
                  {dateValidError ? messageDateValidError : ""}
                </p>
              </div>
              <div className="p-field p-col-12 p-md-6">
                <label className="labels">
                  Categoria<span className="require">*</span>
                </label>
                <AutoComplete
                  value={selectedCategoryComplete}
                  suggestions={filteredCategory}
                  completeMethod={searchCategory}
                  field="descripcion"
                  onChange={onCategoryCompleteChange}
                  placeholder="Seleccione una categoria"
                  dropdown
                  forceSelection
                  filterMatchMode="startsWith"
                  className={categoriaError ? "p-invalid auto" : "auto"}
                />
                <p className="mensajeError">
                  {categoriaError ? mensajeCategoria : ""}
                </p>
              </div>
              <div className="p-field p-col-12 p-md-6">
                <label className="labels">
                  Tipo Actividad<span className="require">*</span>
                </label>
                <AutoComplete
                  value={selectedTipoActividadComplete}
                  suggestions={filteredTipoActividad}
                  completeMethod={searchTipoActividad}
                  field="nombretipoactividad"
                  onChange={onTipoActividadCompleteChange}
                  placeholder="Seleccione un tipo de actividad"
                  dropdown
                  forceSelection
                  filterMatchMode="startsWith"
                  className={tipoActividadError ? "p-invalid auto" : "auto"}
                />
                <p className="mensajeError">
                  {tipoActividadError ? mensajeTipoActividad : ""}
                </p>
              </div>
              <div className="p-field p-col-12 p-md-6">
                <label className="labels">Req.Ticket</label>
                <InputText
                  id="ticket"
                  type="text"
                  name="ticket"
                  keyfilter={/^[^<>*!=°":;_|(#$%&´¨)+-/?¡¿'{}]+$/}
                  onChange={onTicketChange}
                  placeholder=" Registre un ticket"
                  className={ticketError ? "p-invalid" : ""}
                />
                <p className="mensajeError">
                  {ticketError ? mensajeTicket : ""}
                </p>
              </div>
              <div className="p-field p-col-12 p-md-6">
                <label className="labels">
                  Área Negocio<span className="require">*</span>
                </label>
                <AutoComplete
                  value={selectedAreaComplete}
                  suggestions={filteredArea}
                  completeMethod={searchArea}
                  field="nombreareanegocio"
                  onChange={onAreaCompleteChange}
                  placeholder="Seleccione un área de negocio"
                  dropdown
                  forceSelection
                  filterMatchMode="startsWith"
                  className={areaError ? "p-invalid auto" : "auto"}
                />
                <p className="mensajeError">{areaError ? mensajeArea : ""}</p>
              </div>
              <div className="p-field p-col-12 p-md-6">
                <label className="labels">
                  Sistema Aplicación<span className="require">*</span>
                </label>
                <AutoComplete
                  value={selectedSistemaComplete}
                  suggestions={filteredSistema}
                  completeMethod={searchSistema}
                  field="nombreaplicacion"
                  onChange={onSistemaCompleteChange}
                  placeholder="Seleccione un Sistema"
                  dropdown
                  forceSelection
                  filterMatchMode="startsWith"
                  className={sistemaError ? "p-invalid auto" : "auto"}
                />
                <p className="mensajeError">
                  {sistemaError ? mensajeSistema : ""}
                </p>
              </div>
              <div className="p-field p-col-12 p-md-6">
                <label className="labels">
                  Coordinado Con<span className="require">*</span>
                </label>
                <InputText
                  id="coordinadoCon"
                  type="text"
                  name="coordinadoCon"
                  keyfilter={/^[^<0-9>*!=°|(#$%&)+-./?¡¿'{}]+$/}
                  onChange={onCoordinadoConChange}
                  placeholder="Registre con quien coordino la actividad"
                  className={coordinadoConError ? "p-invalid" : ""}
                />
                <p className="mensajeError">
                  {coordinadoConError ? mensajeCoordinadoCon : ""}
                </p>
              </div>
              <div className="p-field p-col-12 p-md-9">
                <label className="labels">
                  Actividad<span className="require">*</span>
                </label>
                <InputText
                  id="actividad"
                  type="text"
                  name="actividad"
                  keyfilter={/^[^<>°|'{}]+$/}
                  onChange={onActividadChange}
                  placeholder="Describa la Actividad que realizó"
                  value={inputActividad.actividad}
                  className={actividadError ? "p-invalid" : ""}
                />
                <p className="mensajeError">
                  {actividadError ? mensajeActividad : ""}
                </p>
              </div>
              <div className="p-field p-col-12 p-md-3">
                <label className="labels">
                  Número Horas<span className="require">*</span>
                </label>
                <InputText
                  id="horas"
                  type="text"
                  name="horas"
                  keyfilter="num"
                  onChange={onHorasChange}
                  placeholder="Registre el número de horas"
                  value={inputHoras.horas}
                  className={horasError ? "p-invalid" : ""}
                />
                <p className="mensajeError">{horasError ? mensajeHoras : ""}</p>
              </div>
            </form>
          </div>
        </Dialog>
      </div>
    </div>
  );
};
export default BtnCrearActividadAdicional;
