import React, { useState, useEffect } from "react";
import { TabView, TabPanel } from 'primereact/tabview';
import DataTable from '../Entregables/DataTable'
import Services from "../../service/Services";
import axios from "axios";
import { CambiarFormatoFecha, isValidDate } from "../Funciones";

const Block9 = ({ permissionsProp, getProjectsProp, getClientsProp, setSelectedDeliverableProp, objectMonthlyReportDataProp, readReportProp, listReportsUsersProp,
    reloadObjectiveProp, runRenderProp, selectedDeliverableProp, allDataProp, showAllDataProp, loadingProp, setListDeliverableProp, monthlyReportDataAllDataProp,
    selectedObjectiveProp, servicesObjectivesProp, servicesWorkTeamProp, servicesClientsProjectUsersProp, projectAssignmentsProp, dateStartPeriodProp, dateEndPeriodProp, monthlyReportProp }) => {

    var condition = false

    if (projectAssignmentsProp !== null) {
        if (projectAssignmentsProp.length !== 0) {
            condition = true
        }
    }

    const [activeIndex, setActiveIndex] = useState(0);
    const [disabledIndex, setDisabledIndex] = useState(false);
    useEffect(() => {
        setActiveIndex(0)
    }, [getProjectsProp])

    useEffect(() => {
        if (loadingProp === true) {
            setDisabledIndex(true)
        } else {
            setDisabledIndex(false)
        }
    }, [runRenderProp, loadingProp])

    const [servicesDeliverables, setServicesDeliverables] = useState([]);
    const [servicesDeliverablesData, setServicesDeliverablesData] = useState([]);
    const [servicesClientsProjectUsers, setServicesClientsProjectUsers] = useState([]);
    const [runRenderBlock, setRunRenderBlock] = useState(0);

    useEffect(() => {

        setServicesClientsProjectUsers(servicesClientsProjectUsersProp)

    }, [servicesClientsProjectUsersProp])// eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(() => {
        const source = axios.CancelToken.source();
    
        const fetchDeliverables = async (idObjetivos, setFunction) => {
            try {
                const data = await getServices.getDeliverablesV2(idObjetivos);
                if (data !== undefined) {
                    setFunction(data);
                }
            } catch (error) {
                console.error(error);
            }
        };
    
        const shouldFetch = () => {
            if (monthlyReportProp === "ownMonthlyReport" && selectedObjectiveProp.length !== 0) {
                const idObjetivos = selectedObjectiveProp.map(objective => objective.id);
                fetchDeliverables(idObjetivos, setServicesDeliverablesData);
            } else if (monthlyReportProp === "monthlyReportOtherOfficial" && servicesObjectivesProp.length !== 0) {
                const idObjetivos = servicesObjectivesProp.map(objective => objective.id);
                fetchDeliverables(idObjetivos, setServicesDeliverablesData);
            } else if (monthlyReportProp === "allMonthlyReportOfOfficial" && showAllDataProp === true && servicesObjectivesProp.length !== 0) {
                const idObjetivos = servicesObjectivesProp.map(objective => objective.id);
                fetchDeliverables(idObjetivos, setServicesDeliverables);
            }
        };
    
        shouldFetch();
    
        return () => {
            if (source) {
                getServices.cancelRequest();
            }
        };
    }, [JSON.stringify(selectedObjectiveProp), JSON.stringify(servicesObjectivesProp), monthlyReportProp, showAllDataProp,runRenderBlock]); // eslint-disable-line react-hooks/exhaustive-deps
      

    useEffect(() => {
        if (objectMonthlyReportDataProp !== undefined && objectMonthlyReportDataProp.objetivos !== '[]') {
            setServicesDeliverables(servicesDeliverablesData)
        }
        else if (readReportProp === true) {
            setServicesDeliverables(servicesDeliverablesData)
        }
    }, [runRenderBlock, objectMonthlyReportDataProp, readReportProp, servicesDeliverablesData])// eslint-disable-line react-hooks/exhaustive-deps

    const [objectives, setObjectives] = useState([]);
    const [deliverables, setDeliverables] = useState([]);
    const [deliverablesExport, setDeliverablesExport] = useState([]);
    const [projectsUserSession, setProjectsUserSession] = useState([]);

    const getServices = new Services();


    useEffect(() => {
        if (showAllDataProp === true) {
            // Nuevo array para almacenar los datos filtrados
            const newDataFiltered = [];
            var newAllDataProp = []
            allDataProp.forEach(item => {
                var workTeamUser = []
                servicesWorkTeamProp.forEach(element => {
                    element.lider.forEach(x => {

                        if (x.colaborador.idusuario.id === item.usuario.id &&
                            getClientsProp.id === element.cliente.id) {
                            if (isValidDate(x.fechafinasignacion,
                                CambiarFormatoFecha(dateStartPeriodProp),
                                CambiarFormatoFecha(dateEndPeriodProp))) {
                                workTeamUser.push(element)
                            }

                        }
                    })
                    element.usuarios.forEach(x => {
                        if (x.usuario.id === item.usuario.id &&
                            getClientsProp.id === element.cliente.id) {
                            if (isValidDate(x.fechafinasignacion,
                                CambiarFormatoFecha(dateStartPeriodProp),
                                CambiarFormatoFecha(dateEndPeriodProp))) {
                                workTeamUser.push(element)
                            }

                        }
                    })
                })

                var period = JSON.parse(item.periodo)
                var deliverables = JSON.parse(item.entregables)
                var newGetDeliverablesFilter = []
                // Se filtra el entregable que del servicio con el del informe para que se actualice si el usuario le da actualizar
                deliverables?.forEach(i => {
                    const filter = servicesDeliverables.filter(obj => {
                        return parseInt(obj.id) === parseInt(i.id)
                    })
                    filter.forEach(x => {
                        newGetDeliverablesFilter.push(x)
                    })
                })
                
                deliverables.forEach(d => {
                    newGetDeliverablesFilter.forEach(y => {
                        //Se filtra solamente el proyecto que tiene ese entregable seleccionado en el informe
                        // con el d.id === y.id valido que si esa entregable está seleccionada para ese proyecto

                        const result = y.objetivos.filter(obj => {
                            return parseInt(obj.proyecto.id) === parseInt(d.proyecto) && 
                            parseInt(d.id) === parseInt(y.id)              
                        })

                        result.forEach(x => {
                            /*    arrObjectives.forEach(o => { */
                            newAllDataProp.push({
                                cliente: item.cliente.razonsocial,
                                periodo: new Date(period.dateEnd).toLocaleString("es-US", { month: "long" }) + ' ' + new Date(period.dateEnd).getFullYear(),
                                equipoTrabajo: workTeamUser.length !== 0 ? workTeamUser[0].nombreequipotrabajo : '',
                                nombreColaborador: item.usuario.nombre + ' ' + item.usuario.apellido,
                                objetivo: x.objetivo.descripcion,
                                entregable: y.descripcion,
                                porcentajeCumplimiento: x.porcentajecumplimiento,
                                fechacumplimiento: x.fechacumplimiento,
                                proyecto: x.proyecto.nombreproyecto,
                                id: y.id
                                /* }) */
                            })
                        })                      
                    })
                })
            })
            const uniqueCombos = {};
            // Recorrer el array original y filtrar los datos
            for (const data of newAllDataProp) {
                const comboKey = `${data.nombreColaborador}-${data.proyecto}-${data.objetivo}-${data.entregable}`;

                if (!uniqueCombos[comboKey]) {
                    uniqueCombos[comboKey] = true;
                    newDataFiltered.push(data);
                }
            }
            setDeliverablesExport(newDataFiltered)

        } else {
            var filterObjectives = []
            selectedObjectiveProp.forEach(element => {
                const result = servicesObjectivesProp?.filter(obj => obj.id === element.id)
                result.forEach(x => {
                    filterObjectives.push(x)
                })
            })
            var uniqueFilterObjectives = [...new Set(filterObjectives)]
            setObjectives(uniqueFilterObjectives)

            var filterObjective = []
            selectedObjectiveProp.forEach(elment => {
                const result = servicesDeliverables?.map(item => {
                    return item.objetivos.map(j => {
                        var boolean
                        if (j.objetivo.id === elment.id) {
                            boolean = item
                        }
                        return boolean
                    })

                })
                result.forEach(i => {
                    i.forEach(j => {
                        if (j !== undefined) {
                            if (j.length !== 0) {
                                filterObjective.push(j)
                            }
                        }
                    })
                })
            })
            var uniqueFilterObjective = [...new Set(filterObjective)]

            if (readReportProp) {
                if (servicesDeliverables.length !== 0) {
                    setDeliverables(servicesDeliverables)
                }
            } else {
                if (servicesDeliverables.length !== 0) {
                    setDeliverables(servicesDeliverables)
                }
            }

            setListDeliverableProp(uniqueFilterObjective)
            setProjectsUserSession(servicesClientsProjectUsers)

        }


    }, [runRenderBlock, allDataProp, showAllDataProp, selectedObjectiveProp, readReportProp, servicesObjectivesProp, servicesDeliverables, servicesClientsProjectUsers, getClientsProp, dateStartPeriodProp, dateEndPeriodProp, servicesWorkTeamProp]) // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <>
            <div >
                <h3 className="labels" style={{ marginBottom: '10px' }}>Entregables</h3>
            </div>
            <div className="tabview-demo">
                <div className="card">
                    {showAllDataProp ?
                        <DataTable
                            permissionsProp={permissionsProp}
                            fullTableProp={true}
                            individualMonthReportProp={true}
                            getClientsProp={getClientsProp}
                            setSelectedDeliverableProp={setSelectedDeliverableProp}
                            selectedDeliverableProp={selectedDeliverableProp}
                            objectMonthlyReportDataProp={objectMonthlyReportDataProp}
                            readReportProp={readReportProp}
                            listReportsUsersProp={listReportsUsersProp}
                            reloadObjectiveProp={reloadObjectiveProp}
                            setDisabledIndexProp={setDisabledIndex}
                            disabledIndexProp={disabledIndex}
                            deliverablesProp={deliverablesExport}
                            objectivesProp={objectives}
                            projectsUserSessionProp={projectsUserSession}
                            runRenderBlockProp={runRenderBlock}
                            setRunRenderBlockProp={setRunRenderBlock}
                            showAllDataProp={showAllDataProp}
                            loadingProp={loadingProp}
                            servicesDeliverablesProp={servicesDeliverables}
                        />
                        :
                        <TabView scrollable activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                            {condition ?
                                projectAssignmentsProp.map((element, i) => {
                                    var sendSelectedObjectiveProp = selectedObjectiveProp.filter(obj => obj.proyecto === element.id)
                                    var panel = (
                                        <TabPanel header={element.project} disabled={disabledIndex} key={i}>
                                            <div className="datatable-responsive-demo">
                                                <div className="card">
                                                    <DataTable
                                                        permissionsProp={permissionsProp}
                                                        fullTableProp={true}
                                                        individualMonthReportProp={true}
                                                        idProjectProp={element.id}
                                                        projectProp={element.project}
                                                        dateStartValidityProp={element.dateStartValidity}
                                                        dateEndValidityProp={element.dateEndValidity}
                                                        getClientsProp={getClientsProp}
                                                        setSelectedDeliverableProp={setSelectedDeliverableProp}
                                                        selectedDeliverableProp={selectedDeliverableProp}
                                                        objectMonthlyReportDataProp={objectMonthlyReportDataProp}
                                                        readReportProp={readReportProp}
                                                        listReportsUsersProp={listReportsUsersProp}
                                                        reloadObjectiveProp={reloadObjectiveProp}
                                                        setDisabledIndexProp={setDisabledIndex}
                                                        disabledIndexProp={disabledIndex}
                                                        deliverablesProp={deliverables}
                                                        objectivesProp={objectives}
                                                        projectsUserSessionProp={projectsUserSession}
                                                        runRenderBlockProp={runRenderBlock}
                                                        setRunRenderBlockProp={setRunRenderBlock}
                                                        showAllDataProp={showAllDataProp}
                                                        monthlyReportDataAllDataProp={monthlyReportDataAllDataProp}
                                                        selectedObjectiveProp={sendSelectedObjectiveProp}
                                                        loadingProp={loadingProp}
                                                        dateStartMonthlyReportProp={dateStartPeriodProp}
                                                        dateEndMonthlyReportProp={dateEndPeriodProp}
                                                    />
                                                </div>
                                            </div>
                                        </TabPanel>
                                    )
                                    return panel
                                })
                                :
                                <TabPanel header="Seleccione un proyecto">
                                    {/* <div className="datatable-responsive-demo">
                                    <div className="card">
                                        <DataTable
                                            permissionsProp={permissionsProp}
                                            individualMonthReportProp={true}
                                            fullTable={false}
                                        />
                                    </div>
                                </div> */}
                                </TabPanel>
                            }
                        </TabView>
                    }
                </div>
            </div>
        </>
    )
}

export default Block9